/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.custom-tooltip {
    background-color: #232F34;
    color: white;
    // padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
